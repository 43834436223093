import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import './FeedbackForm.css';
import './common.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LogoContainer from './LogoContainer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FeedbackForm = () => {
  const initialValues = {
    name: '',
    email: '',
    message: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Lütfen adınızı ve soyadınızı yazın.'),
    email: Yup.string().email('Geçersiz e-posta formatı').required('Lütfen e-posta adresinizi yazın.'),
    message: Yup.string().required('Lütfen geçerli bir mesaj giriniz.'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post('https://picktory-backend.onrender.com/feedback-form', values);
      toast.success('Bizi geliştirdiğiniz için teşekkürler! Görüşlerinizi gerçekten önemsiyoruz.', {
        className: 'toast-container'
      });
      resetForm();
    } catch (error) {
      console.error('Error sending feedback:', error);
      toast.error('Geri bildiriminiz gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.', {
        className: 'toast-container'
      });
    }
    setSubmitting(false);
  };

  return (
    <div className="feedback-form-page">
      <LogoContainer />
      <div className="feedback-content">
        <Link to="/" className="go-back-link">
          <FontAwesomeIcon icon={faArrowLeft} className='black-icon' />    
        </Link>
        <h1 className="feedback-title">Deneyiminizi bizimle paylaşın!</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div>
                <label htmlFor="name">Ad Soyad</label>
                <Field type="text" id="name" name="name" />
                <ErrorMessage name="name" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="email">E-Posta</label>
                <Field type="email" id="email" name="email" />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="message">Mesaj</label>
                <Field as="textarea" id="message" name="message" />
                <ErrorMessage name="message" component="div" className="error" />
              </div>
              <button type="submit" disabled={isSubmitting}>Gönder</button>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer className="toast-container" />
    </div>
  );
};

export default FeedbackForm;
