// src/About.js

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  return (
    <div className="about-page">
      <div className="about-content">
        <Link to="/" className="go-back-link">
          <FontAwesomeIcon icon={faArrowLeft} className="black-icon" />
        </Link>
        <h1 className="about-title">Gloise AI</h1>
        <p className="about-description">
          Gloise'ye hoş geldiniz! Uygulamamız, size özel öneriler sunarak etkinlik, yemek, film, seyahat ve oyunlar arasında karar vermenize yardımcı olur.
        </p>
        <p className="about-description">
          Tercihlerinize göre özenle seçilmiş ve kişiselleştirilmiş önerilerle karar verme sürecinizi kolaylaştırmayı hedefliyoruz.
        </p>
        <p className="about-description">
          Bir kategori seçin ve önerilerimizle sizi şaşırtmamıza izin verin!
        </p>
        <h2 className="about-subtitle" style={{ fontWeight: 'bold' }}>Teknolojimiz Hakkında</h2>
        <p className="about-description">
          Gloise, yapay zekâ destekli iki dil modeliyle güçlendirilmiştir: LLAMA 3 ve AYA 23.
        </p>
        <p className="about-description">
          LLAMA 3, yaratıcı içerik üretme konusunda uzmanlaşmıştır. AYA 23 ise bu içeriği sizin ana dilinize doğal ve akıcı bir şekilde çevirerek size en iyi deneyimi sunar.
        </p>
        <p className="about-description">
          Bu modeller, tarifler, mekanlar, oyunlar ve daha birçok alana ait geniş bir veri kümesiyle eğitilmiştir.
        </p>
      </div>
    </div>
  );
};

export default About;
