import axios from 'axios';

const API_BASE_URL = 'https://picktory-backend.onrender.com';

export const getSessionDetails = async (sessionId) => {
  return axios.get(`${API_BASE_URL}/session-details/${sessionId}`);
};

export const saveSessionDetails = async (sessionDetail) => {
  return axios.post(`${API_BASE_URL}/session-details`, sessionDetail);
};

export const fetchRecommendation = async (categoryId, sessionId) => {
  return axios.post(`${API_BASE_URL}/generate`, { category: categoryId, session_id: sessionId });
};

export const sendFeedback = async (suggestionId, sessionId, feedbackType) => {
  const payload = {
    suggestion_id: suggestionId,
    session_id: sessionId,
    feedback_type: feedbackType,
  };
  return axios.post(`${API_BASE_URL}/feedback`, payload);
};

export const fetchCityFromLocation = async (latitude, longitude) => {
  return axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`);
};
