import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import './App.css';
import './index.css';
import './common.css';
import { isMobile, isTablet, osName, browserName } from 'react-device-detect';
import { Link } from 'react-router-dom';
import LogoContainer from './LogoContainer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSessionDetails, saveSessionDetails, fetchRecommendation, sendFeedback, fetchCityFromLocation } from './api';
import facebookLogo from './icons/facebook_logo.png';
import twitterLogo from './icons/twitter_logo.png';
import whatsappLogo from './icons/whatsapp_logo.png';

const generateSessionId = () => {
  return 'xxxx-xxxx-4xxx-yxxx-xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0;
    var v = (c === 'x') ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
};

const categories = [
  { id: "what_to_do", imgSrc: "/images/neYapsam.png", label: "Ne yapsam?" },
  { id: "what_to_eat", imgSrc: "/images/neYesem.png", label: "Ne yesem?" },
  { id: "what_to_watch", imgSrc: "/images/neIzlesem.png", label: "Ne izlesem?" },
  { id: "where_to_go", imgSrc: "/images/nereyeGitsem.png", label: "Nereye gitsem?" },
  { id: "what_to_play", imgSrc: "/images/neOynasam.png", label: "Ne oynasam?" },
];

const surpriseCategory = { id: "surprise_me", imgSrc: "/images/sasirtBeni.png", label: "Şaşırt beni" };

const App = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [recommendation, setRecommendation] = useState("");
  const [recommendationId, setRecommendationId] = useState(null);
  const [shake, setShake] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState({});
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [sessionId, setSessionId] = useState("");
  const suggestionContainerRef = useRef(null);

  useEffect(() => {
    const savedSessionId = localStorage.getItem('sessionId');
    if (savedSessionId) {
      setSessionId(savedSessionId);
    } else {
      const newSessionId = generateSessionId();
      localStorage.setItem('sessionId', newSessionId);
      setSessionId(newSessionId);
    }
  }, []);

  const saveSessionDetailsCallback = useCallback(async (sessionId, locationData = {}) => {
    if (!sessionId) return;

    try {
      const response = await getSessionDetails(sessionId);
      if (response.data.exists) {
        return;
      }
    } catch (error) {
    }

    const sessionDetail = {
      session_id: sessionId,
      device_type: isMobile ? "Mobile" : isTablet ? "Tablet" : "Browser",
      os_name: osName,
      browser_name: browserName,
      city: locationData.city || null,
      suburb: locationData.suburb || null,
      province: locationData.province || null,
      region: locationData.region || null,
      country: locationData.country || null,
      latitude: locationData.latitude || null,
      longitude: locationData.longitude || null,
    };

    try {
      await saveSessionDetails(sessionDetail);
    } catch (error) {
    }
  }, []);

  const fetchCityFromLocationCallback = useCallback(async (latitude, longitude, sessionId) => {
    if (!sessionId) return;

    try {
      const response = await fetchCityFromLocation(latitude, longitude);
      const address = response.data.address;

      const locationData = {
        session_id: sessionId,
        device_type: isMobile ? "Mobile" : isTablet ? "Tablet" : "Browser",
        os_name: osName,
        browser_name: browserName,
        city: address.town || address.city || address.village,
        suburb: address.suburb,
        province: address.province,
        region: address.region,
        country: address.country,
        latitude: latitude,
        longitude: longitude,
      };

      await saveSessionDetailsCallback(sessionId, locationData);
      localStorage.setItem('locationData', JSON.stringify(locationData)); // Save location data to localStorage

    } catch (error) {
      await saveSessionDetailsCallback(sessionId);
    }
  }, [saveSessionDetailsCallback]);

  useEffect(() => {
    const storedLocationData = localStorage.getItem('locationData');
    if (storedLocationData) {
      const locationData = JSON.parse(storedLocationData);
      saveSessionDetailsCallback(sessionId, locationData)
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            await fetchCityFromLocationCallback(latitude, longitude, sessionId);
          },
          async (error) => {
            console.error("Geolocation error:", error);
            await saveSessionDetailsCallback(sessionId)
          }
        );
      } else {
        saveSessionDetailsCallback(sessionId)
      }
    }
  }, [sessionId, fetchCityFromLocationCallback, saveSessionDetailsCallback]);
  

  const fetchRecommendationHandler = async (category) => {
    try {
      const response = await fetchRecommendation(category.id, sessionId);
      const data = response.data;
      setRecommendation(data.suggestion_tr);
      setRecommendationId(data.id);
      if (suggestionContainerRef.current) {
        suggestionContainerRef.current.scrollTop = 0;
      }
    } catch (error) {
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category.id);
    fetchRecommendationHandler(category);
    setFeedbackMessage("");
  };

  const handleFeedback = async (liked) => {
    const key = `${sessionId}-${recommendationId}`;
  
    if (feedbackSent[key]) {
      return;
    }
  
    const payload = {
      suggestion_id: recommendationId.toString(),
      session_id: sessionId,
      feedback_type: liked ? 'like' : 'dislike',
    };
  
    try {
      await sendFeedback(payload.suggestion_id, payload.session_id, payload.feedback_type);
      setFeedbackSent((prev) => ({ ...prev, [key]: true }));
  
      if (liked) {
        toast.success("Bu öneriyi beğendiniz!");
      } 
  
      setShake(true);
      setTimeout(() => setShake(false), 500);
  
      if (!liked) {
        const category = categories.find(cat => cat.id === selectedCategory) || surpriseCategory;
        if (category) {
          fetchRecommendationHandler(category);
        }
      }
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };
  

  const getShareMessage = () => {
    const category = categories.find(cat => cat.id === selectedCategory) || surpriseCategory;
    const categoryLabel = category ? category.label : "";
    const message = `${categoryLabel}\n\n${recommendation}\n\nMaceraya katıl, Gloise ile öneriler al! -->`;
    return message;
  };

  return (
    <div className={`flex-container ${selectedCategory ? 'suggestion-active' : ''} ${shake ? 'shake' : ''}`}>
      <LogoContainer />
      <div className={`app-content ${selectedCategory ? 'selected' : ''}`}>
        <div className="p-8 flex flex-col items-center w-svw ">
          <div className="flex flex-col items-center gap-4 w-full h-fit p-4 sm:p-16 box-border border-none rounded">
            <div className={`flex ${selectedCategory ? 'justify-center' : 'justify-center'} gap-4 w-full flex-wrap`}>
              {categories.map(category => (
                <div
                  key={category.id}
                  className={`p-4 sm:p-8 size-20 sm:size-32 border box-border rounded-lg cursor-pointer icon-button flex justify-center items-center ${selectedCategory === category.id ? 'bg-lightgreen pulse glow' : ''}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  <img src={category.imgSrc} alt={category.label} className="w-12 h-12" />
                  <span className="tooltip">{category.label}</span>
                </div>
              ))}
            </div>
            <div className="w-full flex justify-center mt-4 sm:mt-8">
              <div
                className={`p-4 sm:p-8 h-20 sm:h-32 w-full max-w-[272px] sm:max-w-[704px] border rounded-lg cursor-pointer text-center icon-button flex justify-center items-center ${selectedCategory === surpriseCategory.id ? 'bg-lightgreen pulse glow' : ''}`}
                onClick={() => handleCategoryClick(surpriseCategory)}
              >
                <img src={surpriseCategory.imgSrc} alt="Şaşırt beni" className="w-12 h-12" />
                <span className="tooltip">Şaşırt beni</span>
              </div>
            </div>
            {selectedCategory && (
              <div className="flex flex-col items-center space-y-4 mt-4 sm:mt-8">
                <div className="suggestion-container fadeIn" ref={suggestionContainerRef}>
                  <p className="suggestion-text">
                    {recommendation}
                  </p>
                </div>
                <div className="feedback-message">{feedbackMessage}</div>
                {!feedbackSent[`${sessionId}-${recommendationId}`] && (
                  <div className="flex space-x-4 sm:space-x-8">
                    <img src="./images/like.png" alt="Like" className={`w-8 h-8 sm:w-12 sm:h-12 cursor-pointer icon-button spin-3d`} onClick={() => handleFeedback(true)} />
                    <img src="./images/dislike.png" alt="Dislike" className={`w-8 h-8 sm:w-12 sm:h-12 cursor-pointer icon-button spin-3d`} onClick={() => handleFeedback(false)} />
                  </div>
                )}
                <div className="flex space-x-4 sm:space-x-8 mt-4">
                  <FacebookShareButton url="https://gloise.com" quote={getShareMessage()}>
                    <img src={facebookLogo} alt="Facebook" width={32} height={32} />
                  </FacebookShareButton>
                  <TwitterShareButton url="https://gloise.com" title={getShareMessage()}>
                    <img src={twitterLogo} alt="Twitter" width={32} height={32} />
                  </TwitterShareButton>
                  <WhatsappShareButton url="https://gloise.com" title={getShareMessage()}>
                    <img src={whatsappLogo} alt="Whatsapp" width={32} height={32} />
                  </WhatsappShareButton>
                </div>
                {feedbackSent[`${sessionId}-${recommendationId}`] && (
                  <div className="next-action-message">
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <footer className="footer">
        <ul className="footer-links">
          <li><Link to="/about">Hakkımda</Link></li>
          <li><Link to="/feedback">Geri Bildirim</Link></li>
        </ul>
      </footer>
      <ToastContainer className="toast-container" />
    </div>
  );
};

export default App;
